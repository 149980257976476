<template>
    <div>
        <step-bar :active="3"></step-bar>
        <!--客户受电工程设计审核申请资料清单-->
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>{{'客户受电工程设计审核申请资料清单'}}</span>
            </div>
            <div class="commonNotice">

                <el-card class="'card-pub-notice'">
                    <div class="commonNotice">
                        <el-checkbox v-model="checkAll1" :true-label="1" :false-label="0"
                                     style="float:right;margin-bottom: 20px;">全选
                        </el-checkbox>

                        <div class="ulbox">
                            <el-table
                                :data="data1"
                                stripe
                                border
                            >
                                <el-table-column
                                    prop="index"
                                    label="序号"
                                    column-key="index"
                                    width="180"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="name"
                                    label="资料名称"
                                    column-key="name"
                                >
                                </el-table-column>
                                <el-table-column label="是否提供">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.status" :true-label="1" :false-label="0"></el-checkbox>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-card>
            </div>
        </el-card>

        <!--客户受电工程竣工报验申请资料清单-->
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>{{'客户受电工程竣工报验申请资料清单'}}</span>
            </div>
            <div class="commonNotice">

                <el-card class="'card-pub-notice'">
                    <div class="commonNotice">
                        <el-checkbox v-model="checkAll2" :true-label="1" :false-label="0"
                                     style="float:right;margin-bottom: 20px;">全选
                        </el-checkbox>
                        <div class="ulbox">
                            <el-table
                                :data="data2"
                                stripe
                                border
                            >
                                <el-table-column
                                    prop="index"
                                    label="序号"
                                    column-key="index"
                                    width="180"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="name"
                                    label="资料名称"
                                    column-key="name"
                                >
                                </el-table-column>
                                <el-table-column label="是否提供">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="scope.row.status" :true-label="1" :false-label="0"></el-checkbox>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-card>
            </div>
        </el-card>

        <div class="submit-btn-group">
            <el-button @click="onCancel">取消</el-button>
            <el-button type="primary" @click="onArchive(0)">保存</el-button>
            <el-button type="primary" @click="onArchive(1)">保存并归档</el-button>
        </div>
    </div>
</template>

<script>
import StepBar from '@/components/StepBar/index';
import {TASK_STATUS} from '@/utils/constants';
export default {
    components: {
        StepBar,
    },
    data() {
        return {
            taskInfo: {},
            type: 'add',
            data: {
                applyId: 0,
                datumArchiveItemForms: [],
                formState: '1',
                workflowTaskId: 0
            },
            dataListLoading: false,
            checkAll1: true,
            data1: [
                {index: '1', name: '《客户受电工程设计及说明书》', status: 1},
                {index: '2', name: '《用电负荷分布图》', status: 1},
                {index: '3', name: '《负荷组成、性质及保安负荷》', status: 1},
                {index: '4', name: '《主要电气设备一览表》', status: 1},
                {index: '5', name: '《节能篇及主要生产设备》', status: 1},
               /* {index: '6', name: '《生产工艺耗电以及允许中断供电时间》', status: 1},*/
                {index: '6', name: '《高压受电装置一、二次接线图与平面布置图》', status: 1},
                {index: '7', name: '《继电保护、过电压保护及电能计量装置的方式》', status: 1},
                {index: '8', name: '《设计单位资质审查资料》', status: 1},
                /*{index: '10', name: '《隐蔽工程设计资料》', status: 1}*/
            ],
            checkAll2: true,
            data2: [
                {index: '1', name: '《客户竣工验收申请书》', status: 1},
                {index: '2', name: '《工程竣工图及说明》', status: 1},
               /* {index: '3', name: '《变更设计说明》', status: 1},
                {index: '4', name: '《隐蔽工程的施工及试验记录》', status: 1},*/
                {index: '3', name: '《电气试验及保护整定调试报告》', status: 1},
                /*{index: '6', name: '《电气工程监理报告和质量监督报告》', status: 1},*/
                {index: '4', name: '《安全用具的试验报告》', status: 1},
                {index: '5', name: '《运行管理的有关规定和制度》', status: 1},
                {index: '6', name: '《值班人员名单及记录》', status: 1},
                {index: '7', name: '其它资料', status: 1}
            ],
            activeNames: ['1', '2']
        };
    },
    watch: {
        checkAll1(newVal) {
            this.data1.forEach(item => {
                item.status = newVal;
            });
        },
        checkAll2(newVal) {
            this.data2.forEach(item => {
                item.status = newVal;
            });
        }
    },
    methods: {
        init(row) {
            this.taskInfo = row;
            this.data.applyId = row.businessFormId || 0;
            this.data.workflowTaskId = row.id || 0;
            this.data.datumArchiveItemForms = [];

            //加载已有数据
            if (this.taskInfo.currentStatus === TASK_STATUS.DATUM_ARCHIVE_SAVED) {
                this.type = 'update';
                // 更新状态
                this.getDatumArchive();
            }

            this.$nextTick(() => {
                //初始化地图
                //this.$refs['ref_elec_map'].init(this.data.applyId);
            });
        },
        getDatumArchive() {
            this.$client.getDatumArchive(this.data.applyId).then((result) => {
                if (result.code !== 0) {
                    this.$message.error(result.msg || '数据获取失败');
                    return;
                }
                //反显数据
                this.data1.forEach(item => {
                    result.data.datumArchiveItems.forEach(d => {
                        if (d.datumName === item.name) {
                            item.status = d.archiveStatus;
                            item.id = d.id;
                        }
                    });
                });

                this.data2.forEach(item => {
                    result.data.datumArchiveItems.forEach(d => {
                        if (d.datumName === item.name) {
                            item.status = d.archiveStatus;
                            item.id = d.id;
                        }
                    });
                });
            });
        },
        async onArchive(type) {
            await this.$confirm(`确认${type === 0 ? '保存' : '保存并归档'}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            this.secondLoading = true;
            this.data.datumArchiveItems = [];

            this.data1.forEach(item => {
                this.data.datumArchiveItems.push({
                    archiveStatus: item.status,
                    datumName: item.name,
                    id: item.id,
                });
            });
            this.data2.forEach(item => {
                this.data.datumArchiveItems.push({
                    archiveStatus: item.status,
                    datumName: item.name,
                    id: item.id,
                });
            });
            this.data.formState = type + '';

            this.$client.addDatumArchive(this.data).then((result) => {
                if (result.code !== 0) {
                    this.$message.error((result.msg || '保存失败').replace('<br>', ''));
                    return;
                }

                this.$message.success(type === 0 ? '保存成功' : '归档成功');

                this.$emit('closeForm', 2);
                this.secondLoading = false;
            });
        },
        onCancel() {
            this.$confirm('确认取消保存？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('closeForm', 2);
            }).catch(e => {
            });
        }
    },
    async created() {
        //TODO 模拟数据
        // await this.$client.findMyHandleTask().then(result => {
        //     const data = result.page.list.filter(x => (x.currentStatus === TASK_STATUS.DATUM_ARCHIVE || x.currentStatus === TASK_STATUS.DATUM_ARCHIVE_SAVED));
        //     this.init(data[0]);
        // });
    },
};
</script>

<style>
</style>
